/**
 * Creates a vertical gap
 * @param {object} param0
 * @param {number|string} param0.$gap - numbers get px appended. Stings (i.e. "1rem") will simply be set to gap. Defaults to 16.
 * @param {boolean} param0.$inline - set to true to for "display: inline-flex;" instead of "display: flex;".
 * @param {number} param0.$flexGrow - set to true to add "flex-grow: 1;"
 * @param {boolean} param0.$reverse - set to true to change flex-direction to "column-reverse" instead of "column"
 * @param {"normal"|"center"|"end"} param0.$alignItems - passed directly to align-items if defined.
 * @returns {string}
 */
const verticalGap = ({
  $inline,
  $reverse,
  $gap: gapArg = 16,
  $alignItems,
  $flexGrow,
}) => {
  const gap = typeof gapArg === "number" ? `${gapArg}px` : gapArg;

  return `
    display: ${$inline ? "inline-flex" : "flex"};
    flex-direction: ${$reverse ? "column-reverse" : "column"};
    ${$flexGrow ? "flex-grow: 1;" : ""}
    gap: ${gap};
    ${$alignItems ? `align-items: ${$alignItems};` : ""}
    transition: gap .3s;
  `;
};

export default verticalGap;
