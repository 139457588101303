import { useLocation } from "react-router-dom";

/**
 *  @description
 * This is a hook to get the query parameters from the URL. These are the parameters that come after the "?" in the URL. It has nothing to do with react-query.
 * @returns {URLSearchParams}
 */
export const useQueryParams = () => {
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  return params;
};

/**
 * @description
 * This function is used to get all the search parameters from the URL and return them as an object.
 * @returns {Object}
 */
export const useQueryParamsKeyValues = () => {
  const params = new URLSearchParams(window.location.search);
  const result = {};

  for (const [key, value] of params.entries()) {
    result[key] = value;
  }

  return result;
}