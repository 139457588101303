import {
  ALERT_EDITOR,
  ASK_THE_MEMBER,
  CAMPAIGNS_SPLASH,
  CONTACT_HISTORY,
  CREATE_ADDON_GIFT,
  CREATE_GROUP,
  CTA_EDITOR,
  DONOR_SUMMARY_REPORTS,
  EXTENDED_FIELDS,
  EXTENDED_FIELD_HISTORY,
  FAMILY_REGISTRATION_SPLASH,
  FAMILY_RELATIONSHIP_PICKER,
  FNC_SMART_GROUP_PROMO,
  FNC_SMART_GROUP_UPGRADE_GROUP_LIMIT,
  FNC_UPGRADE,
  GIFT_REFUND,
  GIVING_SPLASH,
  HOUSEHOLD_DB_VIDEO,
  MANAGE_MY_GIVING_SPLASH,
  MANAGE_PROXY_CONTACTS,
  SACRAMENT_HISTORY,
  SACRAMENT_TRACKING,
  STAX_KYC,
  TWO_FA_DISENROLLMENT,
  TWO_FA_ENROLLMENT,
} from "./contentKeys";

const configsByContentKey = {
  flexHeight: {
    [CREATE_ADDON_GIFT]: true,
    [CREATE_GROUP]: true,
    [CTA_EDITOR]: true,
    [GIFT_REFUND]: true,
    [FNC_UPGRADE]: true,
    [SACRAMENT_TRACKING]: true,
    [MANAGE_PROXY_CONTACTS]: true,
    [FAMILY_RELATIONSHIP_PICKER]: true,
    [EXTENDED_FIELD_HISTORY]: true,
    [ASK_THE_MEMBER]: true,
    [CONTACT_HISTORY]: true,
    [SACRAMENT_HISTORY]: true,
    [FNC_SMART_GROUP_PROMO]: true,
    [FAMILY_REGISTRATION_SPLASH]: true,
    [GIVING_SPLASH]: true,
    [CAMPAIGNS_SPLASH]: true,
    [MANAGE_MY_GIVING_SPLASH]: true,
    [FNC_SMART_GROUP_UPGRADE_GROUP_LIMIT]: true,
    [HOUSEHOLD_DB_VIDEO]: true,
  },
  flexWidth: {
    [CTA_EDITOR]: true,
    [GIFT_REFUND]: true,
    [STAX_KYC]: true,
    [FNC_UPGRADE]: true,
    [FAMILY_RELATIONSHIP_PICKER]: true,
  },
  maxWidth: {
    [CREATE_ADDON_GIFT]: "500px",
    [SACRAMENT_TRACKING]: "1000px",
    [TWO_FA_ENROLLMENT]: "550px",
    [TWO_FA_DISENROLLMENT]: "500px",
    [DONOR_SUMMARY_REPORTS]: "720px",
    [EXTENDED_FIELD_HISTORY]: "550px",
    [ASK_THE_MEMBER]: "600px",
    [CONTACT_HISTORY]: "550px",
    [SACRAMENT_HISTORY]: "550px",
    [CREATE_GROUP]: "550px",
    [FNC_SMART_GROUP_PROMO]: "600px",
    [FAMILY_REGISTRATION_SPLASH]: "600px",
    [GIVING_SPLASH]: "600px",
    [CAMPAIGNS_SPLASH]: "600px",
    [MANAGE_MY_GIVING_SPLASH]: "600px",
    [FNC_SMART_GROUP_UPGRADE_GROUP_LIMIT]: "600px",
    // [HOUSEHOLD_DB_VIDEO]: "800px",
  },
  maxHeight: {
    [STAX_KYC]: "600px",
    [TWO_FA_ENROLLMENT]: "450px",
    [TWO_FA_DISENROLLMENT]: "400px",
    [DONOR_SUMMARY_REPORTS]: "555px",
  },
  minHeight: {
    [SACRAMENT_TRACKING]: "600px",
  },
  legacy: {
    [CREATE_ADDON_GIFT]: true,
    [CTA_EDITOR]: true,
    [GIFT_REFUND]: true,
    [EXTENDED_FIELDS]: true,
    [ALERT_EDITOR]: true,
  },
  closeButtonPosition: {
    // position string or "hide"
  },
  closeOnBackgroundClick: {
    [HOUSEHOLD_DB_VIDEO]: true,
  },
};

export default configsByContentKey;
