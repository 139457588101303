import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import styled from "styled-components/macro";

import { AnimatePresence, motion } from "framer-motion";

import usePointerPosition from "Utilities/Hooks/usePointerPosition";
import useResizeObserver from "Utilities/Hooks/useResizeObserver";

import { HoverContext } from "./";

export function HoverParentInside({
  children,
  disabled = false,
  hoverMargin = 4,
  hoverStyle = {},
  selectedStyle = {},
  triggerResize,
  style,
  CustomSelectedElement,
  CustomHoverElement,
  allowOverflow,
}) {
  const [
    {
      shouldHover,
      hoveringChildRef,
      hoveringChildDisabled,
      forceHoverChildRef,
      selectedChildRef,
    },
    dispatch,
  ] = useContext(HoverContext);

  const parentRef = useRef();

  const [hoverAnimation, setHoverAnimation] = useState({});

  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [hovering, setHovering] = useState(false);
  const [selectedAnimation, setSelectedAnimation] = useState({});

  const [pointerPosition, setPointerPosition] = usePointerPosition();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const windowWidth = window.innerWidth;

  useEffect(() => {
    if (windowWidth !== screenWidth) {
      setScreenWidth(windowWidth);
    }
  }, [screenWidth, windowWidth]);

  useEffect(() => {
    if (!isFirstLoad) return;
    setIsFirstLoad(false);
  }, [isFirstLoad]);

  useEffect(() => {
    dispatch({
      type: "Set Pointer Position Ref",
      pointerPosition,
      setPointerPosition,
    });

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, []);

  const handleSettingSelectedAnimation = () => {
    if (!selectedChildRef?.current) {
      setSelectedAnimation(false);
      return;
    }

    const selectedElementMeasurements =
      selectedChildRef?.current.getBoundingClientRect();
    const wrapperElementMeasurements =
      parentRef.current.getBoundingClientRect();

    const measurements = {
      height: selectedElementMeasurements.height,
      width: selectedElementMeasurements.width,
      x: selectedElementMeasurements.left - wrapperElementMeasurements.left,
      y: selectedElementMeasurements.top - wrapperElementMeasurements.top,
    };

    if (isFirstLoad) {
      return setSelectedAnimation({
        style: {
          ...selectedStyle,
          height: measurements.height,
          width: measurements.width,
          transform: `translate(${measurements.x}px, ${measurements.y}px) scale(1)`,
        },
        initial: {
          ...measurements,
          opacity: 1,
          scale: 1,
        },
      });
    }

    const animations = {
      style: { ...selectedStyle },
      initial: {
        ...measurements,
        opacity: 0,
        scale: 1.2,
      },
      animate: {
        ...measurements,
        scale: 1,
        opacity: 1,
        transition: {
          type: "spring",
          damping: 10,
          mass: 0.5,
          stiffness: 107,
        },
      },
    };

    setSelectedAnimation(animations);

    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  };

  useLayoutEffect(handleSettingSelectedAnimation, [
    selectedChildRef?.current,
    isFirstLoad,
    triggerResize,
    screenWidth,
  ]);

  useLayoutEffect(() => {
    const wrapperElementMeasurements =
      parentRef.current.getBoundingClientRect();
    const mouseOffset = {
      x: pointerPosition?.x - wrapperElementMeasurements.left - hoverMargin * 2,
      y: pointerPosition?.y - wrapperElementMeasurements.top - hoverMargin * 2,
    };

    const childHovering =
      forceHoverChildRef?.current || hoveringChildRef?.current;

    if (
      childHovering &&
      !(
        selectedChildRef?.current && selectedChildRef?.current === childHovering
      ) &&
      (forceHoverChildRef?.current || (shouldHover && !hoveringChildDisabled))
    ) {
      const hoveredElementMeasurements = childHovering.getBoundingClientRect();

      const measurements = {
        height: hoveredElementMeasurements.height - hoverMargin,
        width: hoveredElementMeasurements.width - hoverMargin,
        x:
          hoveredElementMeasurements.left -
          wrapperElementMeasurements.left +
          hoverMargin / 2,
        y:
          hoveredElementMeasurements.top -
          wrapperElementMeasurements.top +
          hoverMargin / 2,
      };

      const animations = {
        style: {
          ...hoverStyle,
          opacity: 0,
          width: "16px",
          height: "16px",
          transform: `translate(${mouseOffset.x}px, ${mouseOffset.y}px)`,
        },
        initial: {
          opacity: 0,
          width: 16,
          height: 16,
          borderRadius: hoverStyle?.borderRadius ? hoverStyle.borderRadus : 8,
          x: mouseOffset.x,
          y: mouseOffset.y,
        },
        animate: {
          ...measurements,
          opacity: 1,
          transition: { type: "spring", damping: 22, stiffness: 240 },
        },
        exit: {
          opacity: 0,
          width: 8,
          height: 8,
          borderRadius: hoverStyle?.borderRadius ? hoverStyle.borderRadus : 8,
          x: mouseOffset.x,
          y: mouseOffset.y,
        },
      };

      if (hoverStyle?.borderRadius >= 0)
        animations.animate.borderRadius = hoverStyle.borderRadius;

      setHoverAnimation(animations);
      setHovering(true);
    } else if (
      (selectedChildRef?.current &&
        selectedChildRef?.current === hoveringChildRef?.current) ||
      hoveringChildDisabled ||
      (!shouldHover && hovering)
    ) {
      setHoverAnimation((currentAnimation) => {
        if (currentAnimation.exit) {
          currentAnimation.exit.x = mouseOffset.x;
          currentAnimation.exit.y = mouseOffset.y;
        }

        if (
          selectedChildRef?.current &&
          hoveringChildRef?.current &&
          currentAnimation?.exit &&
          selectedChildRef?.current === hoveringChildRef?.current
        )
          currentAnimation.exit.transition = { delay: 0.2 };

        return currentAnimation;
      });
      setHovering(false);
    }
    // Aug 2021 → we’re doing a clean slate of all warnings and ain’t got time to look at this so that’s why this is disabled
    // eslint-disable-next-line
  }, [
    hoveringChildRef?.current,
    shouldHover,
    hovering,
    hoveringChildDisabled,
    selectedChildRef?.current,
    forceHoverChildRef?.current,
    triggerResize,
  ]);

  const onHoverStart = (event, info) => {
    setPointerPosition(info.point);
    dispatch({ type: "Should Hover" });
  };
  const onHoverEnd = (event, info) => {
    setPointerPosition(info.point);
    dispatch({ type: "Should Not Hover" });
  };

  const selectedElementProps = {
    className: "SelectedBackground",
    exit: {
      scale: 1.3,
      opacity: 0,
      transition: {
        type: "tween",
        duration: 0.3,
        ease: "backIn",
      },
    },
    ...selectedAnimation,
  };

  const hoverElementProps = {
    className: "HoverBackground",
    ...hoverAnimation,
  };

  return (
    <Wrapper
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      ref={parentRef}
      style={style}
      className="FnrHoverParentInside"
      $allowOverflow={allowOverflow}
    >
      <AnimatePresence>
        {(forceHoverChildRef?.current || !disabled) &&
          hovering &&
          hoverAnimation &&
          (CustomHoverElement ? (
            <CustomHoverElement key="hover" {...hoverElementProps} />
          ) : (
            <HoverBackground key="hover" {...hoverElementProps} />
          ))}
        {selectedChildRef?.current &&
          selectedAnimation &&
          (CustomSelectedElement ? (
            <CustomSelectedElement key="selected" {...selectedElementProps} />
          ) : (
            <HoverSelectedBackground key="selected" {...selectedElementProps} />
          ))}
      </AnimatePresence>

      {children}
    </Wrapper>
  );
}

const Wrapper = styled(motion.div)`
  position: relative;
  ${({ $allowOverflow }) => ($allowOverflow ? "" : "overflow: hidden;")}
  border-radius: 4px;
`;

export const HoverBackground = styled(motion.div)`
  background: ${(props) => (props.hoverBG ? props.hoverBG : "#eceff0")};
  border-radius: ${(props) => props.borderRadius};
  height: 4px;
  width: 4px;
  position: absolute;
  z-index: 1;
`;

export const HoverSelectedBackground = styled(motion.div)`
  z-index: 50;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(178deg, #00abcd, #278dc1 35%);
  border-radius: 4px;
`;
