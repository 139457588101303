import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { DateTime } from "luxon";

/**
 * If a user leaves a window open for a long time without interacting with it,
 * then their browser version can get out of sync with what's in the db.
 * This can lead to situations where they try to act on things that don't exist anymore
 * or other weird stuff.
 *
 * This forces their browser to refresh if left open for 8 hours.
 */
export const useRefreshAfterIdleHook = () => {
  const location = useLocation();

  useEffect(() => {
    setLastWindowFocus();
  }, [location]);

  useEffect(() => {
    window.addEventListener("blur", setLastWindowFocus);
    window.addEventListener("focus", handleWindowFocus);

    return () => {
      window.removeEventListener("blur", setLastWindowFocus);
      window.removeEventListener("focus", handleWindowFocus);
    };
  }, []);
};

function setLastWindowFocus() {
  let now = new Date();
  localStorage.setItem("lastWindowFocus", now.toISOString());
}

function handleWindowFocus() {
  let lastFocus = localStorage.getItem("lastWindowFocus");
  const last = DateTime.fromISO(lastFocus);
  const now = DateTime.now();
  const duration = now.diff(last, "minutes");
  const shouldReload = duration.toObject().minutes > 600;

  if (shouldReload) window.location.reload();
}