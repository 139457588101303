import React, { Suspense, lazy, useContext } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

import { FlocknoteAppState } from "Flocknote/state.js";

import MissingNetworkInfo from "Composer/MissingNetworkInfo";
import { AnimatePresence } from "framer-motion";

import { useQueryParams } from "Utilities/Hooks/useQueryParams";
import { useRefreshAfterIdleHook } from "./refreshAfterIdleHook";

const UIKitPage = lazy(() => import("UI/UIKit/UIKitPage"));
const DevSettings = lazy(() => import("Utilities/DevSettings"));
const Dashboard = lazy(() => import("Dashboard"));
const SignupsPublicPage = lazy(() => import("Signups/SignupsPublicPage"));
const FamilySinglePage = lazy(() =>
  import("Family/ConfirmationLandingPage/ConfirmationLandingPage")
);
const Playground = lazy(() => import("Playground"));
const Login = lazy(() => import("Login"));
const SignupsInSystem = lazy(() => import("Signups/SignupsInSystem.js"));
const TextingBilling = lazy(() => import("Billing/TextingBilling"));
const Importing = lazy(() => import("Importing"));
const UserProfile = lazy(() => import("UserProfile"));
const UserContact = lazy(() => import("Contacts/Drawer"));
const NewBoard = lazy(() => import("Boards/NewBoard"));
const BoardPage = lazy(() => import("Boards/BoardPage"));
const HappeTools = lazy(() => import("HappeTools/Tools"));
const HappeToolsDiosync = lazy(() => import("HappeTools/Diosync/Diosync"));
const BoardsIntroPage = lazy(() => import("Boards/IntroPage"));
const GivingPage = lazy(() => import("Giving/GivingPage"));
const ManageMyGivingPage = lazy(() => import("Giving/ManageMyGivingPage"));
const FundItSettingsPage = lazy(() => import("Giving/FundItSettings"));
const PaymentPage = lazy(() => import("Payments/PaymentPage"));
const EnrollmentPage = lazy(() => import("Payments/EnrollmentPage"));
const PledgePage = lazy(() => import("Giving/Pledging/PledgePage"));
const CreatePledgeModal = lazy(() =>
  import("Giving/Pledging/CreatePledgeModal")
);
const MagicNotesScraper = lazy(() => import("MagicNotes/Scraper"));
const SettingsPanels = lazy(() => import("Network/SettingsPanels"));
const TemplatePage = lazy(() => import("Composer/Template/TemplatePage"));
const TemplateConfigurationPage = lazy(() =>
  import("Composer/Template/TemplateConfigurationPage")
);
const AccountMergePage = lazy(() => import("AccountMerge/AccountMergePage"));
const AccountMergeModal = lazy(() => import("AccountMerge/AccountMergeModal"));
const DeterminePaymentPage = lazy(() =>
  import("Payments/FundItPage/DeterminePaymentPage")
);
const FunditReceiptPage = lazy(() => import("Payments/ReceiptPage"));
const JoinGroupLandingPage = lazy(() => import("Group/JoinGroupLandingPage"));
const MerchantCenterInstantAuth = lazy(() =>
  import("Payments/MerchantCenterInstantAuth")
);
const ReplyToGiveInfo = lazy(() => import("Note/ReplyToGiveInfo"));
const ManualPaymentProcessing = lazy(() =>
  import("Payments/ManualPaymentProcessing")
);
const ScheduledNotesReport = lazy(() => import("Reports/ScheduledNotes"));
const FamilyRegistration = lazy(() => import("FamilyRegistration"));
const NumberManagement = lazy(() => import("NumberManagement"));
const SavedReports = lazy(() => import("Reports/SavedReports"));
const HouseholdDatabase = lazy(() =>
  import("HouseholdDatabase/HouseholdDatabasePortal")
);
const ContactsPage = lazy(() => import("Contacts/ContactsPage"));
const UnsubscribeContactPage = lazy(() => import("Contacts/UnsubscribePage"));
const ParishesPage = lazy(() => import("Parishes/ParishesPage"));
const HealthReportPage = lazy(() =>
  import("Parishes/ParishesPage/HealthReportPage")
);
const SacramentPage = lazy(() => import("SacramentTracking/SacramentPage"));
const SmartGroupFilterPanel = lazy(() => import("Group/SmartGroupFilterPanel"));
const AskTheMemberPrimaryParish = lazy(() => import("CrossFeatureComponents/AskTheMember/AskTheMemberPrimaryParish"));
const AskTheMemberFamilyRelationships = lazy(() => import("CrossFeatureComponents/AskTheMember/AskTheMemberFamilyRelationships"));
const AskTheMemberDuplicates = lazy(() => import("CrossFeatureComponents/AskTheMember/AskTheMemberDuplicates"));
const SmartGroupPromoSplash = lazy(() => import ("Group/SmartGroupPromoSplash")) ;

const NON_REACT_URLS = [
  // "/note",
  // "/mynotes",
  "/unolytics",
  "/subnetworks",
  "/superunolytics",
  "/event",
];

const NON_REACT_GROUP_URLS = [
  "/:group",
  "/:group/people",
  "/:group/notes",
  "/:group/notes/*",
  "/:group/note/*",
  "/:group/user/*",
  "/:group/supertext-packages",
  "/:group/import",
  "/:group/newnetwork",
];

export default function Routes() {
  const isDevEnv = process.env.NODE_ENV === "development";
  useRefreshAfterIdleHook();

  // URL matchers for standalone modals
  const showTextingBilling = Boolean(useRouteMatch("*/supertext-packages"));
  const preImport = Boolean(useRouteMatch("/:groupShortname/people/import"));
  const postImport = Boolean(useRouteMatch("*/import/:importID"));
  const showImporting = preImport || postImport;
  const showUserProfile = Boolean(useRouteMatch("*/user/:userID"));

  // Query param matchers
  const params = useQueryParams();
  const showUserContact = Boolean(params.get("contact"));
  const showEditSmartGroup = Boolean(params.get("editSmartGroup"));
  

  const showAccountMergeModal = Boolean(
    useRouteMatch(["*/*/merge", "*/*/duplicate-profiles"])
  );

  const showSettingsPanels = Boolean(
    useRouteMatch([
      "*/settings/network",
      "*/settings/network-sharing",
      "*/settings/network-welcome",
      "*/settings/network-security",
      "/:groupURL/settings/group",
      "/:groupURL/settings/group-sharing",
      "/:groupURL/settings/group-welcome",
      "/:groupURL/*/settings/group",
      "/:groupURL/*/settings/group-sharing",
      "/:groupURL/*/settings/group-welcome",
      "*/settingspanel/fund",
      "/giving/settings/campaign",
    ])
  );
  const showCreateNewBoard = useRouteMatch([
    "/:groupURL/new-board", // for creating a new board immediately after navigating to a group
    "/:groupURL/*/new-board", // for creating a new board while on the People, Notes, or Boards Intro page
    "/:groupURL/board/*/new-board", // for creating a new board while on an existing board
  ]);
  const showCreatePledgeModal = useRouteMatch("*/*/create-pledge");

  const [{ composer }] = useContext(FlocknoteAppState);

  return (
    <Suspense fallback>
      <Switch>
        {/* Protected (Non-React) URLs */}
        <Route path={NON_REACT_URLS} component={null} />

        {/* Dev-Only URLs */}
        {isDevEnv ? <Route path="/ui" component={UIKitPage} /> : null}
        {isDevEnv ? <Route path="/dev" component={DevSettings} /> : null}
        {isDevEnv ? <Route path="/playground" component={Playground} /> : null}
        {isDevEnv ? (
          <Route path="/magicnotes" component={MagicNotesScraper} />
        ) : null}

        {/* Login Page */}
        <Route path="/login" component={Login} />

        {/* Dashboard */}
        {/* Note: dashboard (sometimes) shows in background when billing is open */}
        <Route path={["/dashboard", "/billing"]} component={Dashboard} />

        <Route path="/contacts" component={ContactsPage} />

        <Route
          path={["/parishes", "/parishes/:subnetworkShortname"]}
          component={ParishesPage}
        />

        <Route path="/healthreport" component={HealthReportPage} />

        {/* Family Confirmation */}
        <Route path="/familyconfirmation" component={FamilySinglePage} />

        {/* Signups (Public Page) */}
        <Route
          path="/signup/:signupListID/give/:fundID"
          component={SignupsPublicPage}
        />
        <Route path="/signup/:signupListID" component={SignupsPublicPage} />

        {/* Signups (Admin Pages) */}
        <Route
          path={[
            "/signups/:signupListID/:signupTab/:signupItemID/:signupItemTab",
            "/signups/:signupListID/:signupTab/:signupItemID",
            "/signups/:signupListID/:signupTab",
            "/signups/:signupListID",
            "/signups",
          ]}
          component={SignupsInSystem}
        />
        {/* FundIt Page */}
        <Route
          path="/fundit/merchantcenter"
          component={MerchantCenterInstantAuth}
        />

        <Route path="/fundit/give" exact>
          <Redirect to="/give" />
        </Route>

        <Route path="/fundit" exact>
          <Redirect to="/onlineGivingEnrollment" />
        </Route>

        <Route
          path={["/give", "/give/recurring/:recurringGiftID"]}
          exact
          component={ManageMyGivingPage}
        />

        <Route
          path="/fundit/receipt/:transactionID"
          component={FunditReceiptPage}
        />
        {/* <Route path="/fundit" component={DeterminePaymentPage} /> */}
        <Route
          path="/onlineGivingEnrollment"
          component={DeterminePaymentPage}
        />

        <Route path="/enrollment" component={EnrollmentPage} />

        <Route path="/merge" component={AccountMergePage} />

        {/* Unsubscribe Contact Page */}
        <Route
          path="/note/:noteID/unsubscribeContact"
          component={UnsubscribeContactPage}
          exact
        />

        {/* Note View */}
        <Route path="*/note/:noteID*" component={ReplyToGiveInfo} />

        {/* Giving */}
        <Route
          path="/giving/settings/:givingSettingsTab*"
          component={FundItSettingsPage}
        />
        <Route path="/giving" component={GivingPage} />

        {/* Payment Page */}
        <Route path="/give/:fundID" component={PaymentPage} />

        {/* Manually Process Payment Page for Happes */}
        <Route
          path="/manualpaymentprocess"
          component={ManualPaymentProcessing}
        />

        <Route path="/schedulednotes/view" component={ScheduledNotesReport} />

        <Route path="/register" component={FamilyRegistration} />

        <Route
          path={["/reports/new", "/reports/:boardID", "/reports"]}
          component={SavedReports}
        />

        <Route path={["/householddatabase"]} component={HouseholdDatabase} />

        {/* Public Pledge Page */}
        <Route path="/pledge/:campaignID" component={PledgePage} />

        {/* IIG Sacraments Page */}
        <Route path="/sacrament" component={SacramentPage} />
        
        {/* Ask the Member Primary Parish */}
        <Route path="/askthemember/relationships" component={AskTheMemberFamilyRelationships} />

        {/* Ask the member duplicates */}
        <Route path="/askTheMember/duplicate/:mergeID" component={AskTheMemberDuplicates} />

        {/* Number Management Page */}
        <Route path="/numbermanagement" component={NumberManagement} />

        {/* Smart Group Promo Splash */}
        {/* Gets the jump on NON_REACT_GROUP_URLS and displays this on a url in that list*/}
        <Route 
          exact
          path="/:groupURL/people"
          component={SmartGroupPromoSplash}
        />

        {/* Protected Group URLs */}
        <Route exact path={NON_REACT_GROUP_URLS} component={null} />

        {/* Boards */}
        <Route
          exact
          path={[
            "*/:groupURL/board",
            "*/:groupURL/boards",
            "*/:groupURL/boards/new-board",
          ]}
          component={BoardsIntroPage}
        />

        <Route
          exact
          path={[
            "/:groupURL/board/:boardURL",
            "/:groupURL/board/:boardURL/*", // for handling both */new-board and */settings board routes
          ]}
          component={BoardPage}
        />

        <Route
          exact
          path={"/group/*/joined"}
          component={JoinGroupLandingPage}
        />

        <Route path="/happes/tools" component={HappeTools} />
        <Route path="/happes/diosync" component={HappeToolsDiosync} />

        {/* Groups */}
        {/* <Route path="/:groupURL" component={Group} /> */}
      </Switch>

      {/* Components Based on `useRouteMatch` URL matching */}
      <AnimatePresence>
        <Suspense fallback>
          {showTextingBilling ? <TextingBilling key="textingBilling" /> : null}
          {showImporting ? <Importing key="importing" /> : null}
          {showSettingsPanels ? <SettingsPanels key="settingsPanels" /> : null}
          {showCreateNewBoard ? (
            <NewBoard
              groupURL={showCreateNewBoard.params.groupURL}
              key="newBoard"
            />
          ) : null}
          {showUserProfile ? <UserProfile key="userProfile" /> : null}
          {showUserContact ? <UserContact key="userContact" /> : null}
          {showEditSmartGroup ? (
            <SmartGroupFilterPanel key="smartGroupFilterPanel" />
          ) : null}
          {showAccountMergeModal ? (
            <AccountMergeModal key="accountMergeModal" />
          ) : null}
          {composer.showTemplates && <TemplatePage key="templatePage" />}
          {composer.showTemplateConfiguration && (
            <TemplateConfigurationPage key="templateConfig" />
          )}
          {showCreatePledgeModal ? <CreatePledgeModal /> : null}
          <MissingNetworkInfo />
        </Suspense>
      </AnimatePresence>
    </Suspense>
  );
}
