import { useContext, useEffect } from "react";

import styled from "styled-components/macro";

import classNames from "classnames";
import { AnimatePresence, motion } from "framer-motion";

import Portal from "Utilities/PortalMaker";

import "./FnrModal_body_styles.scss";
import useFnrModalState, {
  FnrModalProvider,
  FnrModalState,
} from "./fnrModalState";

const childAnimations = {
  variants: {
    initial: {
      scale: 0.5,
      opacity: 0,
      y: "50%",
    },
    open: {
      scale: 1,
      opacity: 1,
      y: 0,
    },
    closed: {
      scale: 0.5,
      opacity: 0,
      y: "50%",
      transition: {
        type: "tween",
        duration: 0.3,
        ease: "backIn",
      },
    },
  },
  initial: "initial",
  animate: "open",
  exit: "closed",
};

export default function FnrModalWithState({
  children,
  show,
  setShow,
  positionTopIfMobile,
  onWillClose,
  onFinishedClosing,
  onBackgroundClick = () => {},
  ...props
}) {
  return (
    <FnrModalProvider
      show={show}
      setShow={setShow}
      positionTopIfMobile={positionTopIfMobile}
      onWillClose={onWillClose}
      onFinishedClosing={onFinishedClosing}
    >
      <FnrModal onBackgroundClick={onBackgroundClick} {...props}>
        {children}
      </FnrModal>
    </FnrModalProvider>
  );
}

function FnrModal({ children, onBackgroundClick = () => {}, ...props }) {
  const { show, animationComplete, positionTopIfMobile, isTouchOnly } =
    useFnrModalState();

  useEffect(() => {
    if (show) document.body.classList.add("FnrModal_showing");
    else document.body.classList.remove("FnrModal_showing");

    return () => document.body.classList.remove("FnrModal_showing");
  }, [show]);

  return (
    <AnimatePresence>
      {show ? (
        <Portal to={document.getElementById("FnrOverlayHolder")}>
          <Backdrop
            className={classNames({
              positionTopIfMobile: positionTopIfMobile && isTouchOnly,
            })}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={onBackgroundClick}
            id="fnrmodalbackground"
          >
            <ModalWrapper
              {...childAnimations}
              onAnimationComplete={animationComplete}
              {...props}
            >
              {children}
            </ModalWrapper>
          </Backdrop>
        </Portal>
      ) : null}
    </AnimatePresence>
  );
}

export function useCloseModal() {
  const context = useContext(FnrModalState);

  if (!context) {
    throw new Error(
      "useCloseModal must be used inside a FlocknoteAppStateProvider"
    );
  }
  if (!context.setShow) {
    throw new Error(
      "setShow must be passed into FnrModal for useCloseModal to work"
    );
  }

  return context.close;
}

const ModalWrapper = styled(motion.div)`
  max-height: 100%; // This is necessary for proper vertical scrolling on small screens
`;

const Backdrop = styled(motion.div)`
  position: fixed;
  z-index: 6800;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  overflow-y: auto;
  overflow-x: hidden;
  background: rgba(0, 0, 0, 0.5);

  display: flex;
  justify-content: center;
  align-items: center;

  &.positionTopIfMobile {
    align-items: flex-start;
    & ${ModalWrapper} {
      margin-top: 40px;
    }
  }
`;

export const ModalContent = styled.div`
  position: relative;
  background: #eceff0;
  max-width: calc(100vw - 60px);
  box-shadow: 0 1px 5px 0 rgba(0, 0, 0, 0.25);
  border-radius: 20px;
  box-sizing: border-box;
  padding: 20px;

  /* CHROME (and anywhere backdrop-filter is supported) */
  @supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
    background: rgba(236, 239, 240, 0.8);
    backdrop-filter: blur(12px) saturate(2);
    -webkit-backdrop-filter: blur(12px) saturate(2);
  }
`;
