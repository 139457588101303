import { node, number, object } from "prop-types";
import React, { createContext, useEffect, useReducer } from "react";

import cloneDeep from "lodash/cloneDeep";
import merge from "lodash/merge";

import FnrEnvironment from "Utilities/FnrEnvironment";

import { useGetNetworkMetadata, useGetUserData } from "./API/queries.js";
import {
  useDrawerUrlParams,
  useModalUrlParams,
} from "./GlobalComponents/urlParamHooks";
import flocknoteActions from "./actions.js";
import { devInitialAppState } from "./state.dev.js";

const initialAppState = {
  networkID: 0,
  rightPanelPortalToElement: false,
  billing: {
    show: false,
  },
  composer: {
    minimized: false,
    showTemplates: false,
    startRemoving: false,
    noteTargets: [],
    showTemplateConfiguration: false,
    loadingComposerTemplate: false,
  },
  userProfile: {
    userID: 0,
    currentEl: null,
    tabs: {
      newsfeed: false,
      info: false,
      groups: false,
      notes: false,
      documents: false,
      giving: false,
      wallet: false,
    },
    givingDataRefreshTrigger: 0,
    closePledgeDetailTrigger: 0,
  },
  networkGiving: {
    refreshPledgeUsers: 0,
    refreshRecurringGiftsList: 0,
  },
  overlays: [],
  currentGroupURL: null,
  currentGroupPanel: null,
  profileDataRefreshTrigger: 0,
  refreshAdditionalContactInfo: 0,
  dashboardTick: 0,
  instantAuth: {
    route: null,
    postData: null,
    phoneNumber: null,
    displayInstantAuth: false,
    callback: null,
    message: null,
    error: null,
    userID: null,
    hideCloseButton: null,
  },
  replyToGive: {
    isReplyToGiveNote: false,
    totalAmountGiven: 0,
    gifts: [],
  },
  donorSummaryDeliverabilityReport: {
    show: false,
    reportID: null,
  },
  taxSummaryModal: {
    show: false,
    year: new Date().getFullYear(),
  },
  missingNetworkInfoModal: {
    show: false,
    data: {},
  },
  showFamilyRegistrationProfileFieldModal: false,
  groupId: 0,
  fundItPermissions: false, // initialized via useFundItPermissions. Value of "false" indicates the need to init call.
  fundItStatus: false, // initialized via useFundItStatus
  staxStatus: false, // initialized via useStaxStatus
  modal: {
    isOpen: false,
    contentKey: "",
    contentProps: {},
  },
  drawer: {
    isOpen: false,
    drawerKey: "",
    drawerProps: {},
  },
  availableProfileTabs: [],
  loadingProfileTab: null,
  profileDataIsLoading: false,
  navigationProfileTabName: null,
  shouldInvalidateActiveHouseholdDetail: false,
  isSmartGroupCreation: false,
};

function flocknoteAppReducer(state, action) {
  let newState = { ...state };

  let foundOverlay;

  switch (action.type) {
    //mostly comes calls outside of React
    case "fn_key_value":
      newState[action.key] = action.value;
      return newState;

    case "Composer Minimized":
      newState.composer.minimized = true;
      return newState;
    case "Composer Maximized":
      newState.composer.minimized = false;
      return newState;

    case "Show Composer Templates":
      newState.composer.showTemplates = true;
      newState.composer.startRemoving = false;
      return newState;

    case "Start Removing Composer Templates":
      newState.composer.startRemoving = true;
      return newState;

    case "Remove Composer Templates":
      newState.composer.showTemplates = false;
      return newState;

    case "Update Note Targets":
      newState.composer.noteTargets = [...action.noteTargets];
      return newState;

    case "Reload Composer Templates":
      newState.composer.noteTargets = [...newState.composer.noteTargets];
      return newState;

    case "Show Save Note as Composer Template":
      newState.composer.showTemplateConfiguration = true;
      return newState;

    case "Refresh and Close Save Note as Composer Template":
      newState.composer.noteTargets = [...newState.composer.noteTargets];
      newState.composer.showTemplateConfiguration = false;
      return newState;

    case "Cancel Save Note as Composer Template":
      newState.composer.showTemplateConfiguration = false;
      return newState;

    case "Start Loading Composer Template Item":
      newState.composer.loadingComposerTemplate = true;
      return newState;

    case "Finished Loading Composer Template Item":
      newState.composer.loadingComposerTemplate = false;
      return newState;

    case "Set Current User Profile":
      newState.userProfile.userID = action.userID;
      newState.userProfile.currentEl = action.element;
      newState.userProfile.userInfo = action?.userInfo;
      return newState;

    case "User Profile Closed":
      newState.userProfile.userID = 0;
      newState.userProfile.currentEl = null;
      for (const tab in newState.userProfile.tabs) {
        newState.userProfile.tabs[tab] = false;
      }
      return newState;

    case "Show an Overlay":
      if (
        newState.overlays.length === 0 ||
        !newState.overlays.find((ol) => ol.ref === action.overlay.ref)
      )
        newState.overlays.push(action.overlay);
      return newState;

    case "Hide an Overlay":
      foundOverlay = newState.overlays
        .map((ol) => ol.ref)
        .indexOf(action.overlay.ref);
      if (foundOverlay >= 0) newState.overlays.splice(foundOverlay, 1);
      return newState;

    case "Refresh User Profile Data":
      newState.profileDataRefreshTrigger =
        newState.profileDataRefreshTrigger + 1;
      return newState;

    case "Refresh Additional Contact Info":
      newState.refreshAdditionalContactInfo =
        newState.refreshAdditionalContactInfo + 1;
      return newState;

    case "Refresh Network Giving Campaign Pledge User List":
      newState.networkGiving.refreshPledgeUsers =
        newState.networkGiving.refreshPledgeUsers + 1;
      return newState;

    case "Refresh Network Giving Recurring Gifts List":
      newState.networkGiving.refreshRecurringGiftsList =
        newState.networkGiving.refreshRecurringGiftsList + 1;
      return newState;

    case "Refresh User Profile Giving Data":
      newState.userProfile.givingDataRefreshTrigger =
        newState.userProfile.givingDataRefreshTrigger + 1;
      return newState;

    case "Close User Profile Pledge Detail":
      newState.userProfile.closePledgeDetailTrigger =
        newState.userProfile.closePledgeDetailTrigger + 1;
      return newState;

    case "User Profile Tab Showing":
      newState.userProfile.tabs[action.tab] = true;
      return newState;

    case "User Profile Tab Not Showing":
      newState.userProfile.tabs[action.tab] = false;
      return newState;

    case "Increment Dashboard Tick":
      newState.dashboardTick = newState.dashboardTick + 1;
      return newState;

    case "Display Instant Auth": {
      newState.instantAuth = {
        ...newState.instantAuth,
        displayInstantAuth: true,
        phoneNumber: action.phoneNumber,
        route: action.route,
        postData: action.postData,
        callback: action.callback,
        message: action.message,
        error: action.error,
        userID: action.userID,
        hideCloseButton: action.hideCloseButton,
      };
      return newState;
    }

    case "Hide Instant Auth":
      newState.instantAuth.displayInstantAuth = false;
      return newState;

    case "Set RTG Note Info":
      newState.replyToGive = action.data;
      return newState;

    case "Show Donor Summary Deliverability Report Modal":
      newState.donorSummaryDeliverabilityReport = {
        show: true,
        reportID: action.value,
      };
      return newState;

    case "Close Donor Summary Deliverability Report Modal":
      newState.donorSummaryDeliverabilityReport = {
        show: false,
        reportID: null,
      };
      return newState;

    case "Show Tax Summary Modal":
      newState.taxSummaryModal = {
        ...newState.taxSummaryModal,
        show: action.value,
        reportID: action.reportID,
      };
      return newState;

    case "Show Missing Network Info Modal":
      newState.missingNetworkInfoModal = {
        ...newState.missingNetworkInfoModal,
        show: action.show,
        data: action.data || {},
      };
      return newState;

    case "Close Group Settings Panel":
      newState.currentGroupPanel.closePanel();
      return newState;

    case flocknoteActions.SET_GROUP_ID:
      newState.groupId = action.groupId;
      return newState;

    case flocknoteActions.SET_SHOW_PEOPLE_SPLASH:
      newState.networkMetadata.showPeopleSplash = action.showPeopleSplash;
      return newState;

    case flocknoteActions.SET_HAS_USED_FP_TRIAL:
      newState.networkMetadata.hasUsedFPTrial = action.hasUsedFPTrial;
      return newState;

    case flocknoteActions.SET_NETWORK_TYPE:
      return {
        ...state,
        networkMetadata: {
          ...state.networkMetaData,
          networkType: action.networkType,
        },
      };

    case flocknoteActions.SET_HAS_ADD_ON_GIFTS:
      return {
        ...state,
        networkMetadata: {
          ...state.networkMetadata,
          hasAddOnGifts: action.hasAddOnGifts,
        },
      };

    case flocknoteActions.SET_METADATA:
      newState.networkMetadata = action.networkMetadata;
      return newState;

    case flocknoteActions.SET_USERDATA:
      newState.userData = action.userData;
      return newState;

    case flocknoteActions.SET_USER_FUNDIT_PERMISSIONS:
      newState.fundItPermissions = action.fundItPermissions;
      return newState;

    case flocknoteActions.SET_FUNDIT_STATUS:
      newState.fundItStatus = action.fundItStatus;
      return newState;

    case flocknoteActions.SET_STAX_STATUS:
      newState.staxStatus = action.staxStatus;
      return newState;

    case flocknoteActions.OPEN_MODAL:
      // TODO: convert all of these to this format so that we're not mutating initialAppState.
      return {
        ...state,
        modal: {
          isOpen: true,
          contentKey: action.contentKey,
          contentProps: action.contentProps,
        },
      };

    case flocknoteActions.CLOSE_MODAL:
      return { ...state, modal: initialAppState.modal };

    case flocknoteActions.OPEN_DRAWER:
      return {
        ...state,
        drawer: {
          isOpen: true,
          drawerKey: action.drawerKey,
          drawerProps: action.drawerProps,
        },
      };

    case flocknoteActions.OPEN_DRAWER_WITH_OLD_PROPS:
      return { ...state, drawer: { ...state.drawer, isOpen: true } };

    case flocknoteActions.CLOSE_DRAWER:
      return { ...state, drawer: { ...state.drawer, isOpen: false } };

    case "setAvailableProfileTabs":
      return { ...state, availableProfileTabs: action.availableProfileTabs };

    case "setLoadingProfileTab": {
      return { ...state, loadingProfileTab: action.loadingProfileTab };
    }

    case "setProfileDataIsLoading":
      return { ...state, profileDataIsLoading: action.profileDataIsLoading };

    case "setNavigationProfileTabName":
      return {
        ...state,
        navigationProfileTabName: action.navigationProfileTabName,
      };

    case "setShouldInvalidateActiveHouseholdDetail":
      return {
        ...state,
        shouldInvalidateActiveHouseholdDetail:
          action.shouldInvalidateActiveHouseholdDetail,
      };

    case "setIsSmartGroupCreation":
      return {
        ...state,
        isSmartGroupCreation: action.isSmartGroupCreation,
      };

    default:
      return newState;
  }
}

const InitModalData = () => {
  const [, init] = useModalUrlParams();
  useEffect(() => {
    init();
  }, [init]);
  return null;
};

const InitDrawerData = () => {
  const [, init] = useDrawerUrlParams();
  useEffect(() => {
    init();
  }, [init]);
  return null;
};

export const FlocknoteAppState = createContext();

export default function FlocknoteAppStateProvider({
  networkID,
  initialState = {},
  children,
}) {
  // Calling these hooks to initialize the queries asap.
  useGetNetworkMetadata();
  useGetUserData();

  if (networkID) initialAppState.networkID = networkID;

  let newInitialAppState = merge(cloneDeep(initialAppState), initialState);

  if (FnrEnvironment.shouldUseDevState())
    newInitialAppState = { ...initialAppState, ...devInitialAppState };

  const value = useReducer(flocknoteAppReducer, newInitialAppState);
  return (
    <FlocknoteAppState.Provider value={value}>
      <InitModalData />
      <InitDrawerData />
      {children}
    </FlocknoteAppState.Provider>
  );
}

FlocknoteAppStateProvider.propTypes = {
  networkID: number,
  initialState: object,
  children: node,
};

export function useFlocknoteAppState() {
  const context = React.useContext(FlocknoteAppState);

  if (!context) {
    throw new Error(
      "useFlocknoteAppState must be used inside a FlocknoteAppStateProvider"
    );
  }

  return context;
}
