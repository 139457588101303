import React, { useReducer } from "react";

export const AccountMergeContext = React.createContext();

export const VIEWS = {
  sideBySide: "sideBySide",
  preview: "preview",
  cancelled: "cancelled",
  success: "success",
  duplicateSideBySide: "duplicateSideBySide",
  duplicateSnoozed: "duplicateSnoozed",
  duplicateCancelled: "duplicateCancelled",
  duplicateMerged: "duplicateMerged",
  duplicateElevatedPriv: "duplicateElevatedPriv",
  duplicateUnmergable: "duplicateUnmergable",
  duplicatesAlreadyMerged: "duplicatesAlreadyMerged"
};

const initialState = {
  currentView: null,
  layoutAnimationComplete: null,
  sideBySidePreviewData: null,
  mergeData: null, // data for the single-profile preview screen
  endUserChoices: { extendedFields: {} },
  finalMergedUserID: null,
  duplicates: [],
  promoteCandidateMergeID: null,
  unmergableMessage: null,
  pendingMergeApprover: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "currentView": {
      return {
        ...state,
        currentView: action.payload,
      };
    }

    case "layoutAnimationComplete": {
      return {
        ...state,
        layoutAnimationComplete: action.payload,
      };
    }

    case "sideBySidePreviewData": {
      return {
        ...state,
        sideBySidePreviewData: action.payload,
      };
    }

    case "mergeData": {
      return {
        ...state,
        mergeData: action.payload,
      };
    }

    case "endUserChoice": {
      return {
        ...state,
        endUserChoices: {
          ...state.endUserChoices,
          ...action.payload,
        },
      };
    }

    case "endUserChoiceExtendedField": {
      return {
        ...state,
        endUserChoices: {
          ...state.endUserChoices,
          extendedFields: {
            ...state.endUserChoices.extendedFields,
            ...action.payload,
          },
        },
      };
    }

    case "finalMergedUserID": {
      return {
        ...state,
        finalMergedUserID: action.payload,
      };
    }

    case "duplicates": {
      return {
        ...state,
        duplicates: action.payload,
      };
    }

    case "updateDuplicateHandledFlag": {
      return {
        ...state,
        duplicates: updateDuplicateHandledFlag({
          state,
          duplicateID: action.duplicateID,
          handled: action.handled,
        }),
      };
    }

    case "setPromoteCandidateMergeID": {
      return {
        ...state,
        promoteCandidateMergeID: action.payload,
      };
    }

    case "setUnmergableMessage": {
      return {
        ...state,
        unmergableMessage: action.payload,
      };
    }

    case "setPendingMergeApprover": {
      return {
        ...state,
        pendingMergeApprover: action.payload,
      };
    }

    case "clearData": {
      return {
        ...state,
        unmergableMessage: null,
        promoteCandidateMergeID: null,
        pendingMergeApprover: {},
      };
    }

    default:
      return state;
  }
};

export function AccountMergeProvider({ children }) {
  return (
    <AccountMergeContext.Provider value={useReducer(reducer, initialState)}>
      {children}
    </AccountMergeContext.Provider>
  );
}

export function useAccountMerge() {
  const context = React.useContext(AccountMergeContext);

  if (!context) {
    throw new Error(
      "useAccountMerge must be used inside an AccountMergeProvider"
    );
  }

  return context;
}

function updateDuplicateHandledFlag({ state, duplicateID, handled }) {
  const newDuplicates = state.duplicates.map((dupe) => {
    if (dupe.duplicateID === Number(duplicateID)) {
      return {
        ...dupe,
        handled: handled,
      };
    } else {
      return dupe;
    }
  });

  return newDuplicates;
}
