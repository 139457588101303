import { shadowVariants } from "theme/constants";

/**
 *
 * @param {object} param0
 * @param {string} param0.$variant - do not use
 * @param {"insetDark"|"subtle"|"insetDark2"} param0.$shadowVariant
 * @param {boolean} $noShadow - set to true to remove shadow
 * @returns
 */
const shadow = ({ $variant, $shadowVariant, $noShadow }) => {
  if ($noShadow) return "";
  switch ($shadowVariant) {
    case shadowVariants.insetDark:
      return "box-shadow: inset rgba(0, 0, 0, 0.2) 0 1px 5px;";
    case "insetDark2":
      return "box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.25);";
    case shadowVariants.subtle:
      return "box-shadow: rgba(0, 0, 0, 0.08) 1px 1px 3px;";
    default: // continue to standard variants
  }

  switch ($variant) {
    case "button":
      return "filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.5));";
    case "selectList":
      return "box-shadow: inset 0 0 8px rgba(0, 0, 0, 0.25);";
    default:
      return "box-shadow: 1px 2px 6px rgba(0, 0, 0, 0.3);";
  }
};

export default shadow;
