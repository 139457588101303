const getAlignItems = ({ $topAlign, $bottomAlign }) => {
  if ($topAlign) return `align-items: flex-start;`;
  if ($bottomAlign) return `align-items: flex-end;`;
  return `align-items: center;`;
};

/**
 *
 * @param {object} param0
 * @param {number|string} param0.$gap
 * @param {number|string} param0.$rowGap
 * @param {boolean} param0.$centered
 * @param {boolean} param0.$fullWidth
 * @param {boolean} param0.$flexGrow
 * @param {string} param0.$justifyContent
 * @param {boolean} param0.$reverse
 * @param {boolean} param0.$wrap
 * @param {boolean} param0.$inline
 * @param {boolean} param0.$topAlign
 * @param {boolean} param0.$bottomAlign
 * @returns {string}
 */
const horizontalGap = ({
  $gap: gapArg = 16,
  $rowGap: rowGapArg,
  $centered,
  $fullWidth,
  $flexGrow,
  $justifyContent,
  $reverse,
  $wrap,
  $inline,
  $topAlign,
  $bottomAlign,
}) => {
  const $gap = typeof gapArg === "number" ? `${gapArg}px` : gapArg;
  const $rowGap = typeof rowGapArg === "number" ? `${gapArg}px` : gapArg;

  const alignItems = getAlignItems({ $topAlign, $bottomAlign });

  return `
    display: ${$inline ? "inline-flex" : "flex"};
    flex-direction: ${$reverse ? "row-reverse" : "row"};
    ${
      $wrap
        ? `flex-wrap: ${$wrap === "reverse" ? "wrap-reverse" : "wrap"};`
        : ""
    };
    gap: ${$gap};
    ${$rowGap ? `row-gap: ${$rowGap};` : ""}
    ${$centered ? "justify-content: center;" : ""};
    ${$fullWidth ? "justify-content: space-between; flex-grow: 1;" : ""}
    ${$flexGrow ? "flex-grow: 1;" : ""}
    ${$justifyContent ? `justify-content: ${$justifyContent};` : ""}
    ${alignItems}
  `;
};

export default horizontalGap;
